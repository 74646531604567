.highlight {
  width: 70%;
}

@media screen and (max-width: 600px) {
  .highlight {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .kid {
    width: 310px;
  }
}

.facebook-btn {
  background-color: #3b5998 !important;
  font-size: 12px !important;
  color: #fff !important;
  width: 100px !important;
}
.twitter-btn {
  background-color: #1dcaff !important;
  font-size: 12px !important;
  color: #fff !important;
  width: 100px !important;
}
.instagram-btn {
  background-color: #c32aa3 !important;
  font-size: 12px !important;
  color: #fff !important;
  width: 100px !important;
}
.youtube-btn {
  background-color: #e52d27 !important;
  font-size: 12px !important;
  color: #fff !important;
  width: 100px !important;
}
.top {
  text-align: center;
  margin-top: 20px;
}
.medal {
  width: 22px;
  height: 25px;
}
.detail {
  font-size: 10px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.uppercase {
  text-transform: uppercase;
}
.studylab {
  width: 347px;
}
@media screen and (max-width: 600px) {
  .studylab {
    width: 310px;
  }
}
.custom-breadcrumb {
  color: #b4252d !important;
}
.bg-custom {
  background-color: #fff !important;
  padding: 10px;
  color: #24a768 !important;
}
#outer {
  height: 370px;
  display: flex;
}

#inner {
  margin: auto;
}
#outer2 {
  display: flex;
}

#inner2 {
  margin: auto;
}
#banner {
  height: 370px;
  display: flex;
}
#banner2 {
  height: 370px;
  display: flex;
  background-image: url('../assets/img/blur.png');
}
#banner2-text {
  width: 700px;
  font-size: 40px;
}
@media screen and (max-width: 600px) {
  #banner2-text {
    width: auto;
    font-size: 25px;
  }
}
#inner-banner {
  margin: auto;
}
.restructure {
  order: 3;
}
@media screen and (max-width: 600px) {
  .restructure {
    order: 3;
  }
}
@media screen and (max-width: 600px) {
  .restructure4 {
    order: 4;
  }
}
.custom-jumbotron-color {
  background-color: #f7700f !important;
  color: #fff;
}
.listitemtext {
  font-size: 20px !important;
}
.custom-link {
  color: #fff !important;
}
.nopadding {
  padding-left: 12px !important;
  padding-right: 0 !important;
}
.partners-card {
  height: 300px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px;
}
.border-gradient {
  border-bottom: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to right, #fc4446, #24a768, #e3dd50);
}
.text-logo {
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  .text-logo {
    font-size: 8px;
  }
}
.mytabs {
  font-size: 13px !important;
}
.mytabes-disabled {
  color: gray !important;
}
